import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private helper = new JwtHelperService();

  constructor(
    private router: Router
  ) { }

  getToken() {
    if (localStorage.getItem('jwt_token')) {
      return localStorage.getItem('jwt_token');
    }
  }

  getUserID() {
    if (this.isLoggedIn()) {
      const token = this.helper.decodeToken(localStorage.getItem('jwt_token'));
      return token['sub'];
    }
  }

  getUserRole() {
    if (this.isLoggedIn()) {
      const token = this.helper.decodeToken(localStorage.getItem('jwt_token'));
      return token['role'];
    }
  }

  getUserActive() {
    if (this.isLoggedIn()) {
      const token = this.helper.decodeToken(localStorage.getItem('jwt_token'));
      return token['active'];
    }
  }

  getUserName() {
    if (this.isLoggedIn()) {
      const token = this.helper.decodeToken(localStorage.getItem('jwt_token'));
      return token['name'];
    }
  }

  getImageUrl() {
    if (this.isLoggedIn()) {
      const token = this.helper.decodeToken(localStorage.getItem('jwt_token'));
      return token['image_url'];
    }
  }

  isLoggedIn() {
    var token;

    if (!localStorage.getItem('jwt_token')) {
      return false;
    } else {
      token = localStorage.getItem('jwt_token');
    }

    return true;
  }

  logout() {
    localStorage.removeItem('jwt_token');
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }

}
