import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Board, Category } from '../_models'
export const API_ENDPOINT = environment.apiURL;

@Injectable({
  providedIn: 'root'
})
export class BoardService {
  private apiURL = environment.apiURL;
  boards$:any[] = [];

  constructor(private http: HttpClient) { }

  getCards() {
    return this.http.get<any>(API_ENDPOINT + '/cards').toPromise().then(
      res => <Category[]>res.data
    );
  }

  getBoards() {
    return this.http.get<any>(API_ENDPOINT + '/boards/index').toPromise().then(
      res => <Board[]>res.data
    );
  }

  storeBoard(name) {
    return this.http.post<any>(API_ENDPOINT + '/boards/store', {name: name});
  }

  cloneBoard(id) {
    return this.http.post<any>(API_ENDPOINT + '/boards/clone', {id: id});
  }

  updateBoard(board:Board) {
    return this.http.put<any>(API_ENDPOINT + '/boards/update', board);
  }

  getBoard(id) {
    return this.http.get<any>(API_ENDPOINT + '/boards/' + id).toPromise().then(
      res => <Board>res.data
    );
  }

  deleteBoard(id) {
    return this.http.delete<any>(API_ENDPOINT + '/boards/' + id);
  }

  getSharedUsersBoard(id) {
    return this.http.get<any>(API_ENDPOINT + '/boards/' + id + '/shared').toPromise().then(
      res => <any>res.data
    );
  }

  share(obj) {
    return this.http.post<any>(API_ENDPOINT + '/boards/share', obj);
  }

  getSubscribers(id) {
    return this.http.get<any>(API_ENDPOINT + '/boards/' + id + '/subscribers').toPromise().then(
      res => <any>res.data
    );
  }
}
