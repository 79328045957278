import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast',
  template: `
    <p-toast [style]="{'white-space': 'pre-line'}" position="bottom-center" [baseZIndex]="5000" styleClass="custom-toast"></p-toast>
  `,
  styles: [
  ]
})
export class ToastComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
