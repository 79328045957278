import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToolbarModule } from 'primeng/toolbar';
import { BadgeModule } from 'primeng/badge';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { BoardSearchComponent } from '../shared/board-search/board-search.component';

@NgModule({
  declarations: [
    LayoutComponent,
    BoardSearchComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ToolbarModule,
    BadgeModule,
    MenuModule,
    SidebarModule,
    ButtonModule,
    InputTextModule,
    TagModule,
    FormsModule,
    DialogModule,
    CheckboxModule,
    ReactiveFormsModule
  ],
  exports: [
    LayoutComponent,
    BoardSearchComponent,
    TagModule,
    FormsModule,
    DialogModule,
    CheckboxModule,
    ReactiveFormsModule
  ]
})
export class CoreModule { }
