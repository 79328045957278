import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './_services/auth.service';
import { UserService } from './_services/user.service';
import { MessageService } from 'primeng/api';
import { User } from './_models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'sciculture-frontend';
  user:User;
  userID:number;
  userName:string;
  userRole:string;
  userImage:string;
  logged:boolean;

  constructor(
    private primengConfig: PrimeNGConfig,
    private authService: AuthService,
    private userService: UserService,
    private messageService: MessageService
  ) {
    this.primengConfig.ripple = true;
  }

  ngOnInit(): void {
    if(this.authService.isLoggedIn()) {
      let _self = this;
      setInterval(function(){
        _self.userService.refreshToken().subscribe(
          result => {
            if('token' in result) {
              localStorage.setItem('jwt_token', result['token']);
            }
          }, response => {
            console.log(response);
            _self.messageService.add(
              {
                severity: 'error',
                summary: 'Error',
                detail: response.error.message
              }
            );
          }
        );
      }, 600000);

      this.userID = this.authService.getUserID();
      this.userService.getUser(this.userID).then(
        data => {
          this.user = data;
          this.userName = this.authService.getUserName() != null ? this.authService.getUserName() : 'Welcome';
          this.userRole = this.authService.getUserRole();
          this.userImage = this.authService.getImageUrl();
        }, err => {
          console.log(err);
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Error',
              detail: err
            }
          );
        }
      )

      this.logged = this.authService.isLoggedIn();
    }
  }

}
