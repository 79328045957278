import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../_models';
export const API_ENDPOINT = environment.apiURL;

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) { }

  getUsers() {
    return this.http.get<any>(API_ENDPOINT + '/users/index').toPromise().then(
      res => <User[]>res.data
    );
  }

  getUser(id) {
    return this.http.get<any>(API_ENDPOINT + '/users/show/' + id).toPromise().then(
      res => <any>res.data
    );
  }

  updateUser(user:User) {
    return this.http.put<any>(API_ENDPOINT + '/users/update', user);
  }

  deleteUser(id) {
    return this.http.delete<any>(API_ENDPOINT + '/users/' + id);
  }

  login(email: string, password: string){
    return this.http.post<any>(API_ENDPOINT + '/users/login', {email: email, password: password});
  }

  register(email: string, password: string){
    return this.http.post<any>(API_ENDPOINT + '/users/register', {email: email, password: password});
  }

  activate(code:string) {
    return this.http.put<any>(API_ENDPOINT + '/users/activate', {authcode: code});
  }

  authcode() {
    return this.http.put<any>(API_ENDPOINT + '/users/authcode', null);
  }

  changePassword(email) {
    return this.http.put<any>(API_ENDPOINT + '/users/recoverycode', {email: email});
  }

  recovery(code, password) {
    return this.http.put<any>(API_ENDPOINT + '/users/recovery', {recoverycode: code, password: password});
  }

  refreshToken() {
    return this.http.post<any>(API_ENDPOINT + '/users/refreshtoken', null);
  }
}
