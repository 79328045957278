import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { User } from 'src/app/_models';
import { AuthService } from 'src/app/_services/auth.service';
import { BoardService } from 'src/app/_services/board.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public appVersion = environment.appVersion;
  public showPopup = environment.showPopup;
  public neverShowSurveyPopup = environment.neverShowSurveyPopup;
  @Input() logged: boolean;
  @ViewChild('profileMenu') profileMenu: any;
  displaySidebar = false;
  profileMenuItems: MenuItem[];
  @Input() userName:string;
  @Input() userRole:string;
  @Input() userImage:string;
  @Input() userID:number;
  @Input() user: User;
  public board:boolean = false;
  demo:boolean = false;
  newPrivateBoards:number = 0;
  newSharedWithOthersBoards:number = 0;
  newSharedWithMeBoards:number = 0;
  displaySurvey:boolean = false;
  surveyDone:string[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    public route: ActivatedRoute,
    private boardService: BoardService
  ) {
    this.profileMenuItems = [];
  }

  ngOnInit(): void {
    if(this.appVersion === 'engaged') {
      document.getElementById('appFavicon').setAttribute('href', 'favicon-d.ico');
    }

    this.router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if(event.url.split('/')[1] == 'board') {
          this.board = true;
        } else {
          this.board = false;
        }

        if(event.url.split('/')[1] == 'demo') {
          this.demo = true;
        } else {
          this.demo = false;
        }

        if (this.authService.isLoggedIn()) {
          this.newPrivateBoards = 0;
          this.newSharedWithOthersBoards = 0;
          this.newSharedWithMeBoards = 0;
          this.boardService.getBoards().then(
            data => {
              data.forEach(board => {
                if(board.status == 'new') {
                  if(board.shared == 'with_me') {
                    this.newSharedWithMeBoards += 1;
                  }
      
                  if(board.shared == 'by_me') {
                    this.newSharedWithOthersBoards += 1;
                  }
      
                  if(board.shared == 'no') {
                    this.newPrivateBoards += 1;
                  }
                }
              });
            }
          )
        }
      }
    });

    this.profileMenuItems = [
      {
      label: 'Account settings',
      routerLink: ['/profile/' + this.userID]
      }, {
      label: 'Log out',
        command: () => {
          this.authService.logout();
        }
      }
    ];
    
    // Show / hide popup
    let survey = this.getCookie('survey');
    const numberLogin = this.getCookie('numberLogin');
    if(!survey) {
      if (this.neverShowSurveyPopup) {
        this.setCookie('survey', 'never');
      } else {
        this.setCookie('survey', 'todo');
      }
      survey = this.getCookie('survey');
    } else {
      if (this.neverShowSurveyPopup) {
        this.setCookie('survey', 'never');
      }
      survey = this.getCookie('survey');
    }

    if(this.authService.isLoggedIn()) {
      if( this.showPopup && survey === 'todo' ) {
        this.displaySurvey = true;
      }
  
      if( Number(numberLogin) >= 5 && survey === 'todo' ) {
        this.displaySurvey = true;
      }
    }
  }

  openSurvey() {
    const url = 'https://docs.google.com/forms/d/e/1FAIpQLSd1ak8HU7JCkwzpMNMEfRQ270nlyB7u8MvEe0MxB8fL3sF0vA/viewform';
    window.open(url, 'new');
    this.setCookie('survey', 'done');
  }

  saveSurveyPreference() {
    if(this.surveyDone.includes('survey_done')) {
      this.setCookie('survey', 'done');
    }
  }

  setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
  }

  getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    
    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  }
}
